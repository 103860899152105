.slider-card {
  position: absolute;
  width: 50%;
  height: fit-content;
  transform: translate(50%,-65%);
  padding: 20px;
}

.slider-card-image {
  position: relative;
  max-height: fit-content;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-card-image .sliderCardImg {
  width: 50%;
  height: 50%;
  padding: 10px;
  object-fit: fill;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slider-card-image .sliderCardImg.hovered-image {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  z-index: 1;
}

.slider-card-buttons {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 5px;
}

.slider-card-buttons button {
  /* background-color: var(--backgroundNav); */
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-size: 2vw;
}

.slider-card-buttons button:hover {
  background-color: #01d28e;
}

.card-button{
  border: solid 2px var(--backgroundNav);
  border-radius: 100%;
  width: 1vw;
  height: 1vw;
  padding: 1.5vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-button.active{
  background-color: var(--backgroundNav);
}

/* @media screen and (max-width:768) {
  
} */

@media screen and (max-width:1200px) {
  /* .container .containerImg {
    height: 50vh;
  } */
  .slider-card-image .sliderCardImg {
    height: 75%;
    width: 75%;
  }
  .card-button{
  
    padding: 3vw;
    
  }
  .slider-card-buttons button {
    /* background-color: var(--backgroundNav); */
    font-size: 3.5vw;
  }
  
}

@media screen and (max-width: 5000px) {
  /* Default styles for screens up to 5000px wide */
    .container .containerImg {
    height: 60vh;
  }
  .slider-card-image .sliderCardImg {
    height: 40%;
    width: 40%;
  }
}

@media screen and (max-width: 2000px) {
  /* Adjust styles for screens up to 2000px wide */
  .slider-card-image .sliderCardImg {
    height: 60%;
    width: 60%;
  }
}

@media screen and (max-width: 1000px) {
  /* Adjust styles for screens up to 1000px wide */
  .slider-card-image .sliderCardImg {
    height: 100%;
    width: 100%;
  }
}
.popup1 {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1200;
  cursor: pointer;
  position: absolute;
bottom: 0vw;
  left: -50%;
  
  width: 100vw; /* Set width to 100vw (viewport width) */
  height: 90vh; /* Set height to 100vh (viewport height) */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image-popup1 {
  max-width: 90%; /* Set the maximum width to 90% of the popup width */
  max-height: 90vh; /* Set the maximum height to 90% of the viewport height */
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center; /* Maintain the image's aspect ratio */
}

/* Close button style (optional) */
.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
