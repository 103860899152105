:root{
  --mainBackGround:#01d28e;
}
/* Define a basic grid layout for larger screens */
.ContactContainer {
  max-width: fit-content;
  height: fit-content;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  align-items: center;
  /* transform: translateY(50%); */
}
.info-details{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

}

.info {
  /* Styles for the FontAwesome icons and information div */
  /* You can add your FontAwesome icons and other content styling here */
  background: var(--mainBackGround);
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: flex-start;
  gap: 50px;
  flex-direction: column;
}
.info div ul {
  list-style: none;
  margin: 10px 0px;
}

.info div ul li {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.form {
  /* Styles for the React form component div */
  /* You can add your React form component styling here */
  width: 100%;
  height: 100%;
  padding: 20px;
}

.form h2{
  text-align: center;
  margin: 10px 0px;
}

/* Media query for screens smaller than 1000px */
@media screen and (max-width: 1000px) {
  .ContactContainer {
    grid-template-columns: 1fr 1fr;
    /* Change to a single column layout */
  }
}

/* Media query for screens smaller than 600px */
@media screen and (max-width: 600px) {
  .ContactContainer {
    /* Adjust the grid layout as needed for smaller screens */
    /* For example, you can switch to a stacked layout or adjust column widths */
    grid-template-columns: 1fr;
    /* Change to a single column layout */
  }
  .info{
    order: 2;
  }
  .form{
    order: 1;
  }
  .info,
  .form {
    /* Add styles to make the content full-width or adjust spacing */
    padding: 10px;
  }
}
/* ContactForm.css */
/* Style for input elements */
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
  margin: 10px;
  text-align: right;
}
.input-group{
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}
.message-group{
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}

/* Style for labels */
div .label {
  font-weight: bold;
}
.m-Lable{
  
  margin: 0px 10px !important;
}
/* Style for the Submit button */
.submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit button[type="submit"] {
  width: 50%;
  margin: auto !important;
  background-color: var(--mainBackGround);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit button[type="submit"]:hover {
  background-color: var(--mainBackGround);
}
