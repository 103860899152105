* {
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
@media screen and (max-width:500px) {
  button.react-multiple-carousel__arrow{
    min-width: 20px !important;
    min-height: 20px !important;
  }
  button.react-multiple-carousel__arrow::before{
    font-size: 11px;
  }
}