.container {
  width: 100%;
  height: fit-content;
  position: relative;
}

.container .containerImg {
  width: 100% !important;
  position: relative;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center left;
  background-attachment: fixed;
}

.container .containerImg::after {
  content: ""; /* Add an empty content */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5; /* Ensure it's above the image */
}

@media screen and (max-width:2000px) {
  .container .containerImg {
    height: 50vh;
  }
  
}