.navbar {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  min-height: fit-content;
  background-color: #fff;

  margin: 0;
  position: static;

}
.logo-img {
  padding-left: 1rem;
  width: 100%;
  height: 100%;
}
.left-nav {
  width: 25%;
}
.right-nav {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2rem;
  gap: 0.5rem;
}

.nav-link {
 
  color: black;
  display: block;
  height: 100%;
  transition: color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
}
.right-nav a {
  margin-right: 4px; /* Adjust this value to create the desired space */
}
.nav-link:hover {
  color: var(--hoverNav);
  transform: scale(1.05); /* Scale up the element slightly on hover */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

/* @media (max-width: 600px) {
  .logo-img {
    font-size: 6vw;
  }
  .nav-link {
    font-size: 6vw;
  }
}
@media (min-width: 1200px) {
 .navbar{
  /* height: fit-content; */
 