:root {
  --backgroundNav: #009f52; 
  --hoverNav : #fc8012

}

*{
  padding: 0%;
  margin: 0%;
  
}
html{
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling when the popup is open */
}