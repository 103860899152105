.sliderContainer {
  height: fit-content;
  position: relative;
}

.slider {
  width: 100%;
  height: fit-content;
  position: static;
}
.slide {
  width: 100%;
  height: fit-content;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  position: static;
}
.slide .homeContiner {
  /* height: 100%; */
  width: 100%;
}

.slide .homeContiner .slideImg {
  position: relative;
  width: 100% !important;
  height: 60vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center left;
  background-attachment: fixed;
}

.slide .homeContiner .slideImg::after {
  content: ""; /* Add an empty content */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.59);
  z-index: 10; /* Ensure it's above the image */
}

.slide .homeContiner .slideImg ~ .slide .homeContiner .overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.current {
  opacity: 1;
  transform: translateX(0);
}
.slide .homeContiner .overlay-container .content {
  position: absolute;
  bottom: 60%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
  border-right: 2px solid #fff;
  padding-left: 1rem;
  width: 100%;
  height: 20%;
  font-size: 1vw;

  text-align: right;
  /* background-color: rgba(0, 0, 0, 0.05); */

  animation: slide-up 1s ease 0.5s;
  animation-fill-mode: forwards;
}

.buttons {
  position: absolute;
  transform: translateY(20%);
  bottom: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.buttons .buttonsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 50px 0px;
}
.buttons .buttonsDiv button {
  padding: 10px 40px;
  border-radius: 45px;
  width: fit-content;
  border: none;
  color: #11944e; /* Corrected color */
  font-weight: 600;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.buttons button.active {
  color: #fff;
  background-color: #11944e;
  background-repeat: no-repeat;
}

.buttons button.active::after {
  content: " ";
  border-left: 2vw solid transparent;
  border-right: 2vw solid transparent;
  border-top: 1.5vw solid #11944e; /* Corrected color */
  position: absolute;
  bottom: -16px;
  left: 50%; /* Adjust the position as needed */
  transform: translateX(-50%); /* Center the triangle horizontally */
}

.h1-div {
  /* background-color: #90bf29; */
  border-radius: 30px 30px 30px 30px;
  position: absolute;
  bottom: 2vw;
  top: 90%;
  left: 50%;
  padding: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateX(-50%);
  width: 40%;

}
.h1-div {
  font-size: 1vw;
  color: #fff;
}

@keyframes slide-up {
  0% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}
.content {
  color: #fff;
  margin-bottom: 1rem;
  border-right: 4px solid #fff;
  width: 100%;
  position: absolute;
  top: 30%;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.current .content {
  opacity: 1;
}
.arrow {
  border: 2px solid #fff;
  border-radius: 50%;
  background: transparent;
  color: #fff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
}

.arrow:hover {
  background-color: #fff;
  color: #777;
}
.next {
  bottom: 50%;
  right: 1.5rem;
}
.prev {
  bottom: 50%;
  left: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .slide .homeContiner .slideImg {
    height: 60vh;
  }
  .h1-div{
   
    /* bottom: 12%; */
     transform: translate(-50%,-120%);
    width: 50%;
    bottom: 5vw;
    font-size: 1.2vw;
    
  } 
}

@media screen and (max-width: 550px) {
  .buttons .buttonsDiv button {
    /* width: 20%; */
    /* font-size: 15%;  */
    background-size: 15%;
    background-position: right;
    background-position: 20%;
  }
  .slide .homeContiner .slideImg {
    height: 60vh;
  }
  .buttons {
    transform: translateY(10%);
  }
  .arrow {
    width: 1rem;
    height: 1rem;
  }
  .slide .homeContiner .overlay-container .content {
    left: 25%;
  }
  /* .h1-div{
   
    bottom: 12%;
   
    
  } */
}
@media screen and (max-width: 5000px) and  (min-width: 3000px){
  .slide .homeContiner .slideImg {
    height: 55vh;
  }

 
 
  .buttons button.active::after {
    content: " ";
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
    border-top: 0.5vw solid #11944e; /* Corrected color */
    position: absolute;
    bottom: -16px;
    left: 50%; /* Adjust the position as needed */
    transform: translateX(-50%); /* Center the triangle horizontally */
  }
  .buttons .buttonsDiv button{
    width: 6%;
    height: 4vh;
    background-size: 15%;
    background-position: right;
    background-position: 20%;
    font-size: 18px;
  }
  .h1-div{
   
    bottom: 0%;
   
    
  }
}
