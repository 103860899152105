/* SliderItem.css */

.sliderContainer  .SliderItem {
  width: 100%;
  padding: 2px;
  z-index: 3;
  
} 

.carousel-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 3rem;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin: 0 10px;
  transition: transform 0.2s ease-in-out;
}

.carousel-item:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.link-item{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.ItemShow {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.react-multi-carousel-list {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  overflow: hidden;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0;
}
.react-multi-carousel-list {
  overflow: hidden;
}
.image-item {
  width: 80%;
  height: 80%;
  align-items: center;
  object-fit: cover;
}

/* Add this CSS to your SliderItem.css file */

.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200; 
  cursor: pointer;
  position: absolute;
  bottom: 0;
  transform: translateY(15%);
}

.popup-content {
  /* background-color: #fff;  */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 100%; 
  max-height: 100%; 
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
 
}

.popup img {
  width: 25%;
  height: 25%; 
  display: block;
  margin: auto;
  
}

/* Close button style (optional) */
.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  
}

@media screen and (max-width: 1000px){
  .popup img {
    width:80%;
    height: 80%; 
    
    
  }
}
